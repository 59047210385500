<template>
  <div class="Box">
    <div class="leftBox">
      <textTitle text="课程认证" />
      <ul>
        <li v-for="(item, index) in schedule1" :key="index">
          <course :name="item.naem" :time="item.time" :text="item.text"/>
        </li>
        
      </ul>
      <!-- <ul style="float: left;width: 50%;overflow: hidden;">
        <li v-for="(item, index) in schedule2" :key="index">
          <course :name="item.naem" :time="item.time" :text="item.text"/>
        </li>
      </ul> -->
    </div>
    <sidebar />
  </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
import course from "./course";
export default {
  components: {
    sidebar,
    textTitle,
    course,
  },
  data() {
    return {
      schedule1:[
        {
          naem:'商务礼仪培训',
          time:'6-12课时/期',
          text:'名师导师亲自带你入门，手把手教学，课后辅导，疑惑解答等问题，都有老师指导。'
        },
         {
          naem:'形象美学',
          time:'6-12课时/期',
          text:'名师导师亲自带你入门，手把手教学，课后辅导，疑惑解答等问题，都有老师指导。'
        },
        {
          naem:'商务礼仪专业指导',
          time:'48课时/期',
          text:'名师导师亲自带你入门，手把手教学，课后辅导，疑惑解答等问题，都有老师指导。'
        },
        {
          naem:'CAMC城市联盟',
          time:'48课时/期',
          text:'名师导师亲自带你入门，手把手教学，课后辅导，疑惑解答等问题，都有老师指导。'
        },
        {
          naem:'CIET国际形象礼仪',
          time:'56课时/期',
          text:'名师导师亲自带你入门，手把手教学，课后辅导，疑惑解答等问题，都有老师指导。'
        },
        {
          naem:'个人形象设计入门初级训练',
          time:'2-4课时/期',
          text:'名师导师亲自带你入门，手把手教学，课后辅导，疑惑解答等问题，都有老师指导。'
        },
         {
          naem:'国际商务礼仪',
          time:'6-72课时/期',
          text:'欧美国际业务合作的重要基础，涵盖接待，餐饮等类容。名师导师亲自带你入门，手把手教学。'
        },
        {
          naem:'集团战略合作',
          time:'56课时/期',
          text:'整包合作，基于协议基础，根据时间组班授课，性价比高，培训效果好，加深记忆和使用效果，名师导师亲自带你入门，手把手教学。'
        },
        {
          naem:'IIC国际形象顾问',
          time:'56课时/期',
          text:'整包合作，基于协议基础，根据时间组班授课，性价比高，培训效果好，加深记忆和使用效果，名师导师亲自带你入门，手把手教学'
        },
        {
          naem:'EET测评国际礼仪师',
          time:'56课时/期',
          text:'整包合作，基于协议基础，根据时间组班授课，性价比高，培训效果好，加深记忆和使用效果，名师导师亲自带你入门，手把手教学。'
        },
      ],
      schedule2:[
        
      ]

    }
  },
};
</script>
<style scoped>
.leftBox ul li{
  width: 50%;
  float: left;
}
</style>