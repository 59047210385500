<template>
  <div class="courseBox">
    <img class="logoBox" src="../../assets/img/course/course1.png" >
    <div class="introduceBox">
      <div class="courseTime">
        <h3>{{name}}</h3>
        <p>{{time}}</p>
      </div>
      <span class="describe">{{text}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    name:String,
    time:String,
    text:String
  }
}
</script>
<style lang="scss" scoped>
.courseBox {
  overflow: hidden;
  margin-top: 28px;
  width: 100%;
  .logoBox {
    width: 100px;
    height: 100px;
    // background-color: rosybrown;
    float: left;
    margin: 0 10px;
  }
  .introduceBox {
    float: left;
  //  height: 100px;
    width: 266px;

    .courseTime {
      overflow: hidden;
      margin: 8px 0;
      font-size: 14px;
      h3 {
        float: left;
      }
      p {
        float: right;
      }
    }
    .describe {
        line-height: 25px;
        color: rgb(136, 136, 136);
        font-size: 14px;

    }
  }
}
</style>